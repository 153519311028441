<template>
  <div class="GlobalTable">
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="this.seachDataList"
      :currentPage="page.pageNum"
      :total="page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="回复" slot="questionAnswer" align="center">
        <template slot-scope="{ row }">
          <el-tooltip
            v-if="row.questionAnswer"
            class="item"
            effect="dark"
            :content="row.questionAnswer"
            placement="top"
          >
            <div class="Remarks">
              {{ row.questionAnswer }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="排序" slot="sort" align="center">
        <template slot-scope="{ row }">
          <el-input-number
            size="small"
            v-model="row.sort"
            @change="handleChangeNum(row)"
          ></el-input-number>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <!-- slot-scope="{ row }" -->
          <el-button
            size="mini"
            type="primary"
            round
            @click="problemClick(row.id)"
            >编辑</el-button
          >
          <el-button size="mini" type="danger" round @click="delClick(row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </GlobalTable>

    <!-- 编辑 -->
    <el-dialog
      title="编辑问题"
      :visible.sync="problemDialogVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="problemClosed"
    >
      <el-form
        :model="formData"
        :rules="formRules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="问题类型" prop="region">
          <el-select
            style="width: 100%"
            v-model="formData.questionTypeId"
            clearable
            placeholder="请选择问题类型"
          >
            <el-option
              v-for="item in option"
              :key="item.questionTypeId"
              :label="item.questionTypeName"
              :value="item.questionTypeId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="问题名称" prop="questionName">
          <el-input
            v-model="formData.questionName"
            maxlength="20"
            placeholder="请输入问题名称（20字内）"
          ></el-input>
        </el-form-item>
        <el-form-item label="问题回复" prop="questionAnswer">
          <el-input
            placeholder="请输入问题回复"
            type="textarea"
            maxlength="200"
            v-model="formData.questionAnswer"
            :autosize="{ minRows: 7, maxRows: 7 }"
            show-word-limit
            resize="none"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="command">
          <el-input
            placeholder="请输入谷歌动态验证码"
            v-model="formData.command"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="FromBack">取 消</el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleConfirmDialog('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- s删除 -->
    <el-dialog
      title="删除问题"
      :visible.sync="delDialogVisible"
      :close-on-click-modal="false"
      width="400px"
      @closed="delClosed"
    >
      <div class="mb-20">确认要删除该条数据吗？</div>
      <el-input
        v-model="delFromData.command"
        placeholder="请输入谷歌动态验证码"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="delBack">取 消</el-button>
        <el-button size="small" type="primary" @click="delConfirmDialog"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "Table",
  data() {
    return {
      loading: false,
      seachDataList: [],
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      problemDialogVisible: false,
      delDialogVisible: false,
      formRules: {
        questionTypeId: [
          {
            required: true,
            trigger: "blur",
            message: "请选择问题类型",
          },
        ],
        questionAnswer: [
          {
            required: true,
            trigger: "blur",
            message: "请输入回复内容",
          },
        ],
        questionName: [
          {
            required: true,
            trigger: "blur",
            message: "请输入问题",
          },
        ],
        command: [
          {
            required: true,
            trigger: "blur",
            message: "请输入动态口令",
          },
        ],
      },
      option: [],
      formData: {
        questionTypeId: "",
        command: "",
        questionName: "",
        questionAnswer: "",
        id: "",
      },
      delFromData: {
        command: "",
        id: "",
      },
      tableData: {
        questionTypeId: "",
      },
      tableColumns: [
        { label: "问题类型", prop: "questionTypeName" },
        { label: "问题", prop: "questionName" },
        // { label: "回复", prop: "questionAnswer" },
        { slotName: "questionAnswer" },
        { label: "最近更新时间", prop: "updateTime" },
        { label: "最近更新人员", prop: "adminAcc" },
        { slotName: "sort" },
        { slotName: "operation" },
      ],
    };
  },
  created() {
    this.getTypeSelect();
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.page.pageNum = 1;
      this.tableData.questionTypeId = newVal.questionTypeId;
      this.handleCurrentChange();
    },
  },
  methods: {
    // 获取问题类型
    getTypeSelect() {
      _api.templateTypeSelect().then((res) => {
        this.option = res.data;
        console.log(this.option);
      });
    },
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.page.pageNum = val;
      }
      const baseRequest = {
        questionTypeId: this.tableData.questionTypeId,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true;
      _api.templateList(baseRequest).then((res) => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
          this.loading = false;
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      });
    },
    // 编辑问题弹窗
    problemClick(id) {
      this.formData.id = id;
      this.formData.command = "";
      _api.templateInfo({ id }).then((res) => {
        console.log(res);
        this.formData.questionTypeId = res.data.questionTypeId;
        this.formData.questionName = res.data.questionName;
        this.formData.questionAnswer = res.data.questionAnswer;
      });
      this.problemDialogVisible = true;
    },
    // 编辑问题弹框关闭
    problemClosed() {
      this.problemDialogVisible = false;
    },
    FromBack() {
      this.problemDialogVisible = false;
    },
    handleConfirmDialog(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.formData);
          _api.templateSaveOrUpdate(this.formData).then((res) => {
            console.log(res);
            if (res.code == 1) {
              this.handleCurrentChange();
              this.problemDialogVisible = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    // 排序
    handleChangeNum(row) {
      const params = {
        id: row.id,
        sort: row.sort,
      };
      _api.templateUpdateSort(params).then((res) => {
        if (res.code === 1) {
          this.handleCurrentChange();
          this.$message.success(res.msg);
        }
      });
    },
    // 删除
    delClick(id) {
      this.delFromData.id = id;
      this.delFromData.command = "";
      this.delDialogVisible = true;
    },
    delClosed() {
      this.delDialogVisible = false;
    },
    delBack() {
      this.delDialogVisible = false;
    },
    delConfirmDialog() {
      if (!this.delFromData.command) {
        this.$message.error("请输入谷歌动态口令");
        return;
      }
      _api.templateDelete(this.delFromData).then((res) => {
        if (this.seachDataList.length == 1 && this.page.pageNum > 1) {
          this.page.pageNum = this.page.pageNum - 1;
        }
        this.handleCurrentChange();
        this.delDialogVisible = false;
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .mr-50 {
    margin-right: 50px;
  }
  .ml-10 {
    margin-left: 10px;
  }
  .mr-10 {
    margin-right: 10px;
  }
  .font-w {
    font-weight: bold;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .flex-jb {
    display: flex;
    justify-content: space-between;
  }
  .flex-as {
    display: flex;
    align-items: flex-start;
  }
}
</style>
