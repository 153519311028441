<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="90"
      :needBtnLoading="true"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item class="el_from" label="问题类型" slot="select">
        <el-select v-model="tableData.questionTypeId" clearable>
          <el-option label="全部" value=""></el-option>
          <el-option
            v-for="item in option"
            :key="item.questionTypeId"
            :label="item.questionTypeName"
            :value="item.questionTypeId"
          ></el-option>
        </el-select>
      </el-form-item>
      <div slot="AddSlot">
        <!-- <el-button size="small" type="warning" @click="addModel('import')"
          >一键导入</el-button
        > -->
        <el-button size="small" type="primary" @click="problemClick"
          >+ 添加问题</el-button
        >
      </div>
    </GlobalForm>
    <el-dialog
      title="添加问题"
      :visible.sync="problemDialogVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="problemClosed"
    >
      <el-form
        :model="formData"
        :rules="formRules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="问题类型" prop="questionTypeId">
          <el-select
            style="width: 100%"
            v-model="formData.questionTypeId"
            clearable
            placeholder="请选择问题类型"
          >
            <el-option
              v-for="item in option"
              :key="item.questionTypeId"
              :label="item.questionTypeName"
              :value="item.questionTypeId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="问题名称" prop="questionName">
          <el-input
            v-model="formData.questionName"
            maxlength="20"
            placeholder="请输入问题名称（20字内）"
          ></el-input>
        </el-form-item>
        <el-form-item label="问题回复" prop="questionAnswer">
          <el-input
            placeholder="请输入问题回复"
            type="textarea"
            maxlength="200"
            v-model="formData.questionAnswer"
            :autosize="{ minRows: 7, maxRows: 7 }"
            show-word-limit
            resize="none"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="command">
          <el-input
            placeholder="请输入谷歌动态验证码"
            v-model="formData.command"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="FromBack">取 消</el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleConfirmDialog('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 一键导出 -->
    <!-- <el-dialog
      title="批量导入问题"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="handleDialogClosed"
    >
      <div class="step">
        <div class="step-title">
          <span>Step1</span>
        </div>
        <div class="step-content">
          <span style="margin-right: 12px">表格下载</span>
          <el-link type="primary" @click="download">点击下载模板表格</el-link>
        </div>
        <div class="step-title">
          <span>Step2</span>
        </div>
        <div class="tip">
          <span class="tip-title">上传表格</span>
          <span class="tip-content"
            >请上传.xls或.xlsx格式的excel文件，大小10M内</span
          >
        </div>
        <div class="step2-content">
          <el-upload
            ref="upload"
            :limit="1"
            :auto-upload="false"
            :http-request="uploadFile"
            :headers="headers"
            :before-upload="onUpload"
            accept=".xls,.xlsx"
            action="customize"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选取文件</el-button
            >
          </el-upload>
        </div>
        <el-input
          v-model="formData.remark"
          placeholder="请输入谷歌动态验证码"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click.native="handleSuccess"
          >确定</el-button
        >
        <el-button size="small" @click.native="handleClose">取消</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import _api from "@/utils/request";
import { encryptByDES } from "@/utils/3DES";

export default {
  name: "Form",
  props: {
    accountType: {
      require: true,
      type: String,
    },
  },
  data() {
    return {
      initData: null,
      problemDialogVisible: false,
      // dialogVisible: false,
      formItemList: [{ slotName: "select" }],
      // 添加问题表单验证
      formRules: {
        questionTypeId: [
          {
            required: true,
            trigger: "blur",
            message: "请选择问题类型",
          },
        ],
        questionAnswer: [
          {
            required: true,
            trigger: "blur",
            message: "请输入回复内容",
          },
        ],
        questionName: [
          {
            required: true,
            trigger: "blur",
            message: "请输入问题",
          },
        ],
        command: [
          {
            required: true,
            trigger: "blur",
            message: "请输入动态口令",
          },
        ],
      },
      // 下拉
      option: [],
      // 查询
      tableData: {
        questionTypeId: "",
      },
      // 表单
      formData: {
        questionTypeId: "",
        command: "",
        questionName: "",
        questionAnswer: "",
      },
    };
  },
  created() {
    this.getTypeSelect();
    this.handleConfirm();
  },
  watch: {},
  methods: {
    // 获取问题类型
    getTypeSelect() {
      _api.templateTypeSelect().then((res) => {
        this.option = res.data;
        console.log(this.option);
      });
    },
    handleConfirm(data,cd) {
      let SeachParams = {};
      SeachParams = {
        questionTypeId: this.tableData.questionTypeId,
        pageNum: 1,
        pageSize: 10,
        disuseLoding: () => {
          if (typeof cd === 'function') {
            cd()
          }
        },
      };
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
    // 添加问题弹窗
    problemClick() {
      this.formData = {
        questionTypeId: "",
        command: "",
        questionName: "",
        questionAnswer: "",
      };
      this.problemDialogVisible = true;
    },
    // 添加问题弹框关闭
    problemClosed() {
      this.problemDialogVisible = false;
    },
    FromBack() {
      this.problemDialogVisible = false;
    },
    handleConfirmDialog(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.formData);
          _api.templateSaveOrUpdate(this.formData).then((res) => {
            console.log(res);
            if (res.code == 1) {
              this.handleConfirm();
              this.problemDialogVisible = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    // 批量导出
    // handleDialogClosed() {
    //   this.dialogVisible = false;
    // },
    // addModel(val) {
    //   this.dialogVisible = true;
    // },
    // download() {
    //   const link = document.createElement("a");
    //   link.href = "/static/导入机型模板.xlsx";
    //   link.setAttribute("download", "导入机型模板.xlsx");
    //   document.body.appendChild(link);
    //   link.click();
    // },
    // uploadFile(params) {
    //   console.log("uploadFile", params);
    //   const _file = params.file;
    //   // 通过 FormData 对象上传文件
    //   var formData = new FormData();
    //   formData.append("file", _file);
    //   // 发起请求
    //   _api.importAdd(formData).then((res) => {
    //     console.log("_RequestUploads_", res);
    //     if (res.code === 1) {
    //       this.$message({
    //         type: "success",
    //         message: res.msg,
    //       });
    //     } else {
    //       this.$message({
    //         type: "warning",
    //         message: res.msg,
    //       });
    //     }
    //   });
    // },
    // onUpload(e) {
    //   this.headers.TOKEN = encryptByDES(
    //     encryptByDES(
    //       new Date().getTime().toString(),
    //       "63e42c2444e94c1ebca21d30d2aa39a5"
    //     ),
    //     localStorage.getItem("token") || ""
    //   );
    // },
    // handleSuccess() {
    //   this.dialogVisible = false;
    //   if (this.dialogTitle == "添加机型") {
    //     _api.modelAdd(this.modelform).then((res) => {
    //       if (res.code === 1) {
    //         this.$message.success("操作成功");
    //         const SeachParams = {
    //           pageNum: 1,
    //           pageSize: 10,
    //         };
    //         this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    //       } else {
    //         this.$message.error("操作失败");
    //       }
    //     });
    //   } else {
    //     this.$refs.upload.submit();
    //   }
    // },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
  .step {
    .step-title {
      color: #0981ff;
      margin-bottom: 10px;
      padding-left: 6px;
      border-left: 3px solid;
      border-radius: 1.5px;
    }
    .step-content,
    .step2-content {
      margin-bottom: 26px;
      display: flex;
    }
    .step2-content > div {
      display: flex;
    }
    .step2-content {
      .upload-demo {
        display: flex;
        justify-content: start;
        .el-upload-list {
          width: 200px;
        }
      }
    }
    .tip {
      margin-bottom: 12px;
      .tip-title {
        color: #666666;
        margin-right: 12px;
      }
      .tip-content {
        color: #ff687b;
        font-size: 12px;
      }
    }
  }
}
</style>
